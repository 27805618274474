import React,{useEffect} from "react";
import mob from "./Images/mob.png";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useSpring, animated } from "react-spring";
import { animateScroll as scroll } from "react-scroll";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import reactjs from "./Images/reactjs.png";
import vue from "./Images/vue.png";
import node from "./Images/node.png";
import wordpress from "./Images/wordpress.png";
import back from "./Images/1.jpg";
import AOS from 'aos';


const useStyles = makeStyles((theme) => ({
  button: {
    align: "center",
    borderRadius: 15,
  },
  head: {
    // color: "#FF883B",
    // fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "30px",
    // marginBottom: "2.5vw",
    // marginTop: "3vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",

      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
      },
    },

    subtitle: {},
  },
}));

const Web = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, [])

return (
    <>
      <img
        src={back}
        style={{ width: "100%", height: "100%", marginTop: "1%" }}
      ></img>
      <div style={{ margin: "7%", marginTop: "8%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div style={{ textAlign: "center" }} data-aos="fade-right" data-aos-delay="10000">
              <img src={mob} height="100%" width="50%"></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} data-aos="fade-left" data-aos-delay="10000">
            <Typography
              style={{ color: "#1E266D", fontWeight: "bold" }}
              variant="h5"
              className={classes.head}
            >
              Web Development
            </Typography>

            <p className={classes.subtitle}>
              <br />
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English It is a long
              established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. as opposed to using
              'Content here, content here', making it look like readable English
            </p>
            <br />
          </Grid>
        </Grid>
      </div>
      <div style={{}}data-aos="fade-zoom-in" data-aos-duration="3000" >
        <Typography
          style={{ color: "#1E266D", marginTop: "10%", fontType: "Roboto" }}
          variant="h4"
          align="center"
          gutterBottom
        >
          Our Technologies
        </Typography>
        <Typography align="center" color="textSecondary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Typography>
      </div>
      <div style={{ margin: "3%", marginTop: "3%" }}>
        <Grid container spacing={3} align="center" justify="center">
          <Grid item xs={12} sm={6} md={3} data-aos="fade-right" data-aos-delay="10000">
            <img src={reactjs} widith="130px" height="130px"></img>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <img src={vue} widith="220px" height="120px"></img>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <img src={node} widith="100px" height="130px"></img>
          </Grid>
          <Grid item xs={12} sm={6} md={3} data-aos="fade-left" data-aos-delay="10000">
            <img src={wordpress} widith="100px" height="150px"></img>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Web;
