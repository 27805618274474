import React, { useState, useEffect } from "react";
import {
  Nav,
  NavLink,
  // Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  MobileIcon,
  NavItem,
  Link,
} from "./NavbarElements";
import logo from "./images/logo1.jpeg";
import { FaBars } from "react-icons/fa";
import BannerTop from "../Pages/BannerTop";
import { animateScroll as scroll } from "react-scroll";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <a href="/">
         
          <img src={logo} widith="70" height="70" style={{marginLeft:10}}></img>
         
        </a>
        <MobileIcon onClick={toggle}>
          <FaBars />
        </MobileIcon>
        <NavMenu>
          {/* <NavItem> */}
          <Link style={{textDecoration:"none"}}
            to="/"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Home
          </Link>
          <NavLink
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Services
          </NavLink>
          <NavLink
            to="products"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Products
          </NavLink>
          <NavLink
            to="company"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Testimonials
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavBtnLink
            to="contactus"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Contact Us
          </NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
