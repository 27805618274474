import React, { useState,useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSpring, animated, config } from "react-spring";
import { NavBtn, NavBtnLink } from "./servicesButton";
import AOS from 'aos';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {},
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
    title: {
        color: "#1E266D",
        fontFamily: "Roboto",
        fontWeight: 450,
        fontSize: "38px",
        marginTop: "3%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "26px",
        },
    },

        head: {
    color: "#1E266D",
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "30px",
    marginBottom: "2.5vw",
    marginTop: "3vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    root: {
      maxWidth: 345,
    },
  },
}));


export default function Album() {
  // const [flip, set] = useState(false)
  // const props = useSpring({
  //     to: {opacity: 1},
  //     from: {opacity: 0},
  //     reset: false,
  //     reverse: flip,
  //     delay: 200,
  //     config: config.molasses,
  //     onRest: () => set(!flip),
  // })

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, [])

  const classes = useStyles();

  return (
    <React.Fragment id="services">
      <CssBaseline />
      <main>
        {/* Hero unit */}
        {/* <animated.h1 style={props}> */}
        <div style={{ marginTop: "3%",marginBottom:'3%'}}  id="services">
          <Container maxWidth="sm">
              <Typography
                  className={classes.title}
                  variant="h3"
                  align="center"
                  gutterBottom
              >
                  Our Services & Solutions
              </Typography>

            <Typography align="center" color="textSecondary">
             Our team provides high quality services and solutions to our customers.
            </Typography>
          </Container>
        </div>
        <Container>
          <Grid container spacing={4}>
            <Grid item md={4} sm={6} xs={12}>
              <Card className={classes.root}>
                <CardMedia data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                  image="1.jpg"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                    Web Development
                  </Typography>
                  <Typography color="textSecondary">
                 We design and develop responsive websites using the latest set of technologies.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="/web">
                      <Button
                        style={{ color: "#1DABF2" }}
                        size="small"
                        color="primary"
                      >
                        View Our <br /> Work >
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{ marginTop: "5%" }}>
              <Card>
                <CardMedia  data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                            image="mob-app.jpeg"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                    Mobile Development
                  </Typography>
                  <Typography color="textSecondary">
                 We do Mobile Development using both native and hybrid technologies.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="/mobile">
                      <Button
                        style={{ color: "#1DABF2", textDecoration:"none" ,border:"none"}}
                        size="small"
                        color="primary"
                      >
                       View Our <br /> Work>
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{ marginTop: "8%" }}>
              <Card>
                <CardMedia  data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                  image="ux.png"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                    UI UX Designing
                  </Typography>
                  <Typography color="textSecondary">
                   Our expertise design prototypes and live UI design for the customers.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="uiuxdesign">
                      <Button
                        style={{ color: "#1DABF2" }}
                        size="small"
                        color="primary"
                      >
                        View Our <br /> Work>
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Card>
                <CardMedia  data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                  image="tool.jpeg"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                    Enterprise Solutions
                  </Typography>
                  <Typography color="textSecondary">
                    We provide our customers with tailor made Enterprise solutionx.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="enterprise">
                      <Button
                        style={{ color: "#1DABF2" }}
                        size="small"
                        color="primary"
                      >
                         View Our <br /> Work>
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{ marginTop: "5%" }}>
              <Card>
                <CardMedia  data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                            image="robot.jpeg"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                    IOT
                  </Typography>
                  <Typography color="textSecondary">
                    IOT based projects done according to client requirments based on different platforms & devices.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="iot">
                      <Button
                        style={{ color: "#1DABF2" }}
                        size="small"
                        color="primary"
                      >
                       View Our <br /> Work>
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
            <Grid item md={4} sm={6} xs={12} style={{ marginTop: "8%" }}>
              <Card>
                <CardMedia  data-aos="fade-right" data-aos-delay="10000"
                  className={classes.cardMedia}
                  image="stock.jpeg"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h6">
                      Artificial Intelligence
                  </Typography>
                  <Typography color="textSecondary">
                    We develop AI models which can analysis & provide predictions in different areas.
                  </Typography>
                </CardContent>
                <CardActions>
                  <NavBtn>
                    <NavBtnLink to="artificial">
                      <Button
                        style={{ color: "#1DABF2" ,boder:"none" }}
                        size="small"
                        color="primary"
                      >
                          View Our <br /> Work>
                      </Button>
                    </NavBtnLink>
                  </NavBtn>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
